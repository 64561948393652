import { ApiResponse } from '../models/ApiResponse';
import { FormConfig, FormTemplatePagedFilter, GetAllTemplatesRequest } from '../models/Form';
import BaseService from './BaseService';
import TemplateFormServiceBase from './TemplateFormServiceBase';

export default class TemplateFormService extends TemplateFormServiceBase {
  // #region Builder API

  public static createTemplate(data: Record<string, unknown>): Promise<ApiResponse<FormConfig>> {
    return super.create('/v1/template/forms', data);
  }

  public static updateTemplate(formId: string, data: Record<string, unknown>): Promise<ApiResponse<FormConfig>> {
    return super.update(`/v1/template/forms/${formId}`, data);
  }

  public static archiveTemplate(formId: string): Promise<ApiResponse<boolean>> {
    return super.archive(`/v1/template/forms/${formId}/archive`);
  }

  public static restoreTemplate(formId: string): Promise<ApiResponse<boolean>> {
    return super.restore(`/v1/template/forms/${formId}/restore`);
  }

  public static getAllTemplates(request: GetAllTemplatesRequest): Promise<ApiResponse<FormConfig[]>> {
    return super.getAll('/v1/template/forms', request);
  }

  public static getAllTemplatesPaged(filter: FormTemplatePagedFilter, fromCache = true): Promise<ApiResponse<FormConfig[]>> {
    return super.getAllPaged('/v1/template/forms/paged', filter, fromCache);
  }

  public static getFormTemplate(
    formId: string,
    version: number | undefined = undefined,
    excludeSections: boolean | undefined = undefined,
  ): Promise<ApiResponse<FormConfig>> {
    return super.getById(`/v1/template/forms/${formId}`, formId, version, excludeSections);
  }

  public static getShares(formId: string): Promise<ApiResponse<string[]>> {
    return super.get(`/v1/template/forms/${formId}/shared-with`);
  }

  public static shareWithClient(formId: string, formVersion: number, clientId: string): Promise<ApiResponse<boolean>> {
    return super.post(`/v1/template/forms/share-with/${clientId}`, { templateId: formId, templateVersion: formVersion });
  }

  public static revokeSharingWithClient(formId: string, formVersion: number, clientId: string): Promise<ApiResponse<boolean>> {
    return super.post(`/v1/template/forms/revoke-sharing/${clientId}`, { templateId: formId, templateVersion: formVersion });
  }
  // #endregion

  public static getAllUsedTemplates(_: GetAllTemplatesRequest): Promise<ApiResponse<FormConfig[]>> {
    // Not used in admin-section (without a client id)
    return BaseService.emptyResponse([]);
  }

  public static isLinkedToClient(_: string): Promise<ApiResponse<boolean>> {
    return BaseService.emptyResponse(true);
  }
}
